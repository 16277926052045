<template>
  <div class="page-bg">
    <div class="bg"></div>
    <!-- <div class="bg-img" v-if="bgImg">
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'PageBg',
  props: {
    bgImg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  created() { },
  methods: {}
}
</script>

<style lang='scss' scoped>
</style>