<template>
  <div class="app-contianer">
    <PageBg bgImg />

    <div id="app-content">
      <div class="page-type">
        <div class="item" :class="pageType === 'code' ? 'active' : ''" @click="pageType = 'code'">推广码</div>
        <div class="item" :class="pageType === 'statistics' ? 'active' : ''" @click="pageType = 'statistics'">推广统计</div>
      </div>

      <div class="page-item code" v-show="pageType === 'code'">
        <!-- <div class="title f38 tac"><strong>推广员规则明细</strong></div>
      <div class="content f38">
        <p>1. 此为商家推广员, 成为推广员后, 推荐进来的商家每完成一笔订单都会有{{profit}}元提成</p>
        <p>2. 注意: 成为推广员后, 您的提现将会收取{{tx_rate}}%手续费</p>
      </div> -->
        <van-swipe indicator-color="#1989fa">
          <van-swipe-item>
            <div class="code-title f40">商家推广码</div>
            <div class="code-box">
              <div v-show="false" id="shopQrcode" ref="shopQrcode"></div>
              <img :src="shopCodeImg">
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="code-title f40">推广员码</div>
            <div class="code-box">
              <div v-show="false" id="promoterQrcode" ref="promoterQrcode"></div>
              <img :src="promoterCodeImg">
            </div>
          </van-swipe-item>
        </van-swipe>

        <div class="f36 tac">左右滑动切换二维码</div>
      </div>

      <div class="page-item" v-show="pageType === 'statistics'">
        <div class="type-title">
          <div class="type-item f38" :class="summaryType === 'shop' ? 'active':''" @click="changeSummaryType('shop')">
            <div>{{totalInfo.promoterShop ? totalInfo.promoterShop : 0}}</div>
            <div>商家总数</div>
          </div>
          <!-- <div class="type-item f38" :class="summaryType === 'user' ? 'active':''">
          <div>--</div>
          <div>用户总数</div>
        </div> -->
          <div class="type-item f38" :class="summaryType === 'promoter' ? 'active':''"
            @click="changeSummaryType('promoter')">
            <div>{{totalInfo.promoterUser ? totalInfo.promoterUser : 0}}</div>
            <div>推广员总数</div>
          </div>
        </div>

        <div class="search-box" v-if="summaryType ==='shop'">
          <van-search v-model="keywords" shape="round" background="transparent" placeholder="请输入搜索店铺名称"
            @search="searchShop()" />
        </div>

        <div class="table-box f38">
          <table v-if="summaryType ==='shop'">
            <tr>
              <th>店铺ID</th>
              <th>店铺名称</th>
              <th>联系电话</th>
            </tr>
            <tr v-for="item in shopList" :key="item.shopId">
              <td>{{item.shopId}}</td>
              <td>{{item.name}}</td>
              <td>{{item.serviceTel}}</td>
            </tr>
          </table>

          <template v-if="summaryType ==='promoter'">
            <div v-for="item in promoterList" :key="item.custId">
              <div class="promoter-item">
                <div class="info flex">
                  <span>用户ID: {{item.custId}}</span>
                  <span>手机号码: {{item.phone}}</span>
                </div>
                <div class="data flex tac">
                  <div class="item">
                    <div class="margin-tb2">推广商家总数</div>
                    <div class="f40">{{item.shopCount}}</div>
                  </div>
                  <div class="item">
                    <div class="margin-tb2">团队总人数</div>
                    <div class="f40">{{item.teamCount}}</div>
                  </div>
                  <div class="item">
                    <div class="margin-tb2">贡献佣金</div>
                    <div class="f40">{{item.parentProfit}}</div>
                  </div>
                </div>
              </div>
              <van-divider />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mallApi, tfoodApi } from '../../api'
import PageBg from '@/components/PageBg.vue'
import QRCode from 'qrcodejs2'

export default {
  name: 'promoter',
  components: { PageBg },
  data() {
    return {
      pageType: 'code',
      // profit: '--',
      // tx_rate: '--',
      codeType: 'shop',
      summaryType: 'shop',
      totalInfo: {},
      keywords: '',
      shopParams: {
        trxCode: 'MS906',
        page: 1,
        pageSize: 50
      },
      shopList: [],
      shopCodeImg: '',
      promoterParams: {
        trxCode: 'MS907',
        page: 1,
        pageSize: 50
      },
      promoterList: [],
      promoterCodeImg: '',
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'agentConfig']),
  },
  created() {
    // this.getPromoterInfo()
    this.getTotalInfo()
    this.getshopList()
    this.getPromoterList()
  },
  mounted() {
    this.makerCode()
    window.addEventListener('scroll', this.scrollListener)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),

    // 切换二维码
    changeCodeType(val) {
      if (this.codeType === val) {
        return
      }
      this.codeType = val
    },

    // 切换统计表格
    changeSummaryType(val) {
      if (this.summaryType === val) {
        return
      }
      this.summaryType = val
    },

    // 生成二维码
    makerCode() {
      this.$nextTick().then(() => {
        // 商家推广码
        let shopUrl = `${this.agentConfig.SELF_DOMAIN_SHOP}/shop/addshop?agentId=${localStorage.getItem('agent')}&promoterId=${this.custMsg.custId}`
        let shopQrcode = new QRCode('shopQrcode', {
          width: 180, // 二维码宽度
          height: 180, // 二维码高度
          text: shopUrl,
        })
        // 安卓长按保存兼容
        let shopCanvasDiv = document.getElementById('shopQrcode')
        let shopCanvas = shopCanvasDiv.querySelector('canvas')
        this.shopCodeImg = shopCanvas.toDataURL('image/png')

        // 推广推广员码
        let promoterUrl = `${this.agentConfig.SELF_DOMAIN_USER}?agentId=${localStorage.getItem('agent')}&type=03&promoterId=${this.custMsg.custId}`
        let promoterQrcode = new QRCode('promoterQrcode', {
          width: 180, // 二维码宽度
          height: 180, // 二维码高度
          text: promoterUrl,
        })
        // 安卓长按保存兼容
        let promoterCanvasDiv = document.getElementById('promoterQrcode')
        let promoterCanvas = promoterCanvasDiv.querySelector('canvas')
        this.promoterCodeImg = promoterCanvas.toDataURL('image/png')
      })
    },

    // 获取统计数据
    getTotalInfo() {
      tfoodApi({
        trxCode: 'MS901',
      }).then((res) => {
        if (res.trxCode) {
          this.totalInfo = res
        }
      })
    },

    // 获取推广店铺列表
    getshopList() {
      tfoodApi(this.shopParams).then(res => {
        if (res.merchants) {
          this.shopList = this.shopList.concat(res.merchants)
        }
      })
    },

    // 获取推广店铺列表
    getPromoterList() {
      tfoodApi(this.promoterParams).then(res => {
        if (res.promoterSummary) {
          this.promoterList = this.promoterList.concat(res.promoterSummary)
        }
      })
    },

    // 滚动监听
    scrollListener() {
      //总高度=滚动条滚动的高度+cliebtHeight（要是想不到底部就加载数据可以写成）
      //offsetHeight +clientHeight>=scrollHeight-n (n是任意数字)
      const clientHeight = document.documentElement.clientHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const scrollHeight = document.body.scrollHeight
      var hegit = clientHeight + scrollTop
      var scroll = scrollHeight - 100
      if (hegit >= scroll) {
        if (this.flag === false) {
          this.flag = true
          this.page++
          this.getList()
        }
      }
    },

    // 获取推广员配置
    getPromoterInfo() {
      let params = {
        trxCode: 'XF913',
        type: 'TX_PROMOTER'
      }
      mallApi(params).then(res => {
        if (res.hasOwnProperty('profit') && res.hasOwnProperty('tx_rate')) {
          this.profit = res.profit
          this.tx_rate = res.tx_rate

          if (this.isPromoter) {
            this.makerCode()
          }
        } else {
          this.$toast('平台未设置推广员配置')
        }
      })
    },
  }
}
</script>

<style lang='less' scoped>
.page-type {
  display: flex;
  justify-content: space-around;
  margin: 12px;

  .item {
    position: relative;
    text-align: center;
    font-size: 3.8vw;
    color: #666666;
  }
  .item.active {
    font-weight: bold;
    color: #222222;
  }
  .item.active::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 20px;
    margin-left: -10px;
    height: 4px;
    border-radius: 2px;
    bottom: -6px;
    left: 50%;
  }
}

.page-item.code {
  margin: 15vw 8vw 0;
}
.content p {
  margin: 2vw 0;
}
.code-title {
  display: flex;
  justify-content: space-around;
  margin: 2vw 0 6vw 0;
  font-weight: bold;
}
.code-box {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.van-swipe {
  margin-bottom: 12px;
}
/deep/ .van-swipe__indicator {
  width: 16px;
  height: 4px;
  border-radius: 4px;
  background: #888;
}

.type-title {
  display: flex;
  justify-content: space-around;

  .type-item {
    padding: 2vw 3vw;
    // border-radius: 1vw;
    text-align: center;
    border: 1px solid #fff;
  }
  .type-item.active {
    // border-bottom: 1px solid #9e7bff;
    font-weight: bold;
  }
}
.table-box {
  table {
    width: 100%;
  }
  td {
    text-align: center;
  }
  tr td {
    padding: 2vw 1vw;
  }
}
.promoter-item {
  margin-bottom: 4vw;
  .info {
    margin-top: 2vw;
    justify-content: space-between;
  }
  .data {
    justify-content: space-between;
  }
}
</style>